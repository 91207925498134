var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-dropdown',{staticClass:"float-right",attrs:{"variant":"black","right":"","toggle-class":"arrow-none card-drop"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"mdi mdi-dots-horizontal"})]},proxy:true}])},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("Settings")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("Download")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("Upload")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("Action")])]),_c('h4',{staticClass:"header-title mb-3"},[_vm._v("Revenue History")]),_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-borderless table-hover table-centered m-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.revenueData),function(data){return _c('tr',{key:data.date},[_c('td',[_c('h5',{staticClass:"m-0 font-weight-normal"},[_vm._v(_vm._s(data.marketplaces))])]),_c('td',[_vm._v(_vm._s(data.date))]),_c('td',[_vm._v(_vm._s(data.tax))]),_c('td',[_vm._v(_vm._s(data.payout))]),_c('td',[_c('span',{staticClass:"badge",class:{
                    'bg-soft-success text-success': `${data.status}` === 'Paid',
                    'bg-soft-warning text-warning':
                      `${data.status}` === 'Upcoming',
                    'bg-soft-danger  text-danger':
                      `${data.status}` === 'Overdue'
                  }},[_vm._v(_vm._s(data.status))])]),_vm._m(1,true)])}),0)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',[_vm._v("Marketplaces")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("US Tax Hold")]),_c('th',[_vm._v("Payouts")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('a',{staticClass:"btn btn-xs btn-secondary",attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"mdi mdi-pencil"})])])
}]

export { render, staticRenderFns }